import React, { useState, useEffect } from 'react';

export function Pay({formData, setFormData, questionAnswered, setQuestionAnswered, submited}) {

    const [inputsFilled, setInputsFilled] = useState(false);

    const handleInputChange = () => {
        const inputs = document.querySelectorAll('.form-register');
        let allFilled = true;

        inputs.forEach(input => {
        if (input.value === '') {
            allFilled = false;
        }
        });

        setInputsFilled(allFilled);
    };

  useEffect(() => {
    if (inputsFilled) {
      setQuestionAnswered(1);

      //Because we dont use bodyfat in backend
      setFormData(prevFormData => {
        return {
          ...prevFormData,
          bodyfat: "SKINNY"
        };
      });

    } else {
      setQuestionAnswered(1);
    }
  }, [inputsFilled, setQuestionAnswered]);

    return (
        <>
        <label className="register-label">שם פרטי</label>
        <input className="form-text form-register" type="text" value={formData.firstName}
        onChange={(e) => {setFormData({...formData, firstName: e.target.value}); handleInputChange(e)}}
        />

        <label className="register-label">שם משפחה</label>
        <input className="form-text form-register" type="text" value={formData.lastName}
        onChange={(e) => {setFormData({...formData, lastName: e.target.value}); handleInputChange(e)}}
        />

        <label className="register-label">סיסמה</label>
        <input className="form-text form-register" type="password" value={formData.password}
        onChange={(e) => {setFormData({...formData, password: e.target.value}); handleInputChange(e)}}
        />

        <label className="register-label">אימות סיסמה</label>
        <input className="form-text form-register" type="password" value={formData.confirmpassword}
        onChange={(e) => {setFormData({...formData, confirmpassword: e.target.value}); handleInputChange(e)}}
        />

        <button className="button-blue" onClick={() => submited()}>
        הרשמה
        </button>

        </>
    )
}

export default Pay
