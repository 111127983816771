import React, { useState, useEffect, useRef} from 'react';

export function Info2({page, setPage, questionAnswered, setQuestionAnswered, formData, setFormData}) {

    const chartRef = useRef(null);
    const myChartRef = useRef(null); // Reference to store the chart instance
    const currentDate = new Date();
    const threeMonthsLater = new Date();
    threeMonthsLater.setMonth(currentDate.getMonth() + 3);
    const formattedDate = threeMonthsLater.toLocaleDateString('he-IL');
    const [weightIncrement, setWeightIncrement] = useState(0);
    const [weightMin, setWeightMin] = useState(0);
    const [weightMax, setWeightMax] = useState(0);

    useEffect(() => {
        setQuestionAnswered(1);
        //setFormData({ weight: 125, goal: "HEALTH" });
    }, []);

    useEffect(() => {
        switch (formData.goal) {
            case "FIT":
                setWeightIncrement(-4);
                setWeightMin(Number(formData.weight) - 13);
                setWeightMax(Number(formData.weight) + 1);
                break;
            case "HEALTH":
                setWeightIncrement(-2);
                setWeightMin(Number(formData.weight) - 7);
                setWeightMax(Number(formData.weight) + 1);
                break;
            default:
                setWeightIncrement(3);
                setWeightMin(Number(formData.weight) - 1);
                setWeightMax(Number(formData.weight) + 10);
                break;
        }
    }, [formData]);

    useEffect(() => {
        console.log(weightIncrement);
        console.log(formData.goal);
        console.log(Number(formData.weight));
    }, [weightIncrement]);
    
    
    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        // Destroy the previous chart instance if it exists
        if (myChartRef.current) {
            myChartRef.current.destroy();
        }

        // Data for the chart
        const data = {
            labels: ['עכשיו', '', 'בעוד חודש', '', 'בעוד חודשיים'],
            datasets: [
                {
                    data: [
                        Number(formData.weight),
                        Number(formData.weight) + weightIncrement,
                        Number(formData.weight) + weightIncrement*1.3,
                        Number(formData.weight) + weightIncrement*2.7,
                        Number(formData.weight) + weightIncrement*3,
                    ],
                    backgroundColor: 'rgba(197, 255, 16, 0.3)',
                    borderColor: 'rgba(197, 255, 16, 1)',
                    borderWidth: 1,
                },
            ],
        };

        // Options for the chart
        const options = {
            scales: {
                y: {
                    beginAtZero: false,
                    min: weightMin,
                    max: weightMax,
                    ticks: {
                        stepSize: 2,
                        color:"#fff",
                    },
                    grid: {
                        color: 'rgba(43, 43, 60, 1)', // Change grid line color
                        borderColor: 'rgba(43, 43, 60, 1)', // Change axis line color
                        lineWidth: 1, // Change the width of the grid lines
                    },
                },
                x: {
                    grid: {
                        color: 'rgba(43, 43, 60, 1)', // Change grid line color
                        borderColor: 'rgba(43, 43, 60, 1)', // Change axis line color
                        lineWidth: 1, // Change the width of the grid lines
                    },
                    ticks: {
                        display: false,
                    }
                },
            },
            plugins: {
                legend: {
                    display: false,  // Hide the legend
                },
            },
            animation: {
                onComplete: () => {
                    // Disable animation after the first render
                    options.animation = false;
                },
                // Customize animation for only the points after the first one
                delay: (context) => {
                    return context.dataIndex === 0 ? 0 : context.dataIndex * 200; // No delay for the first point, delay for others
                },
            },
            elements: {
                line: {
                    tension: 0.4, // Make the line more progressive with a curved shape
                },
            },
        };

        // Create a new chart instance and store it in the ref
        myChartRef.current = new Chart(ctx, {
            type: 'line',
            data: data,
            options: options,
        });
    }, [weightIncrement,weightMin,weightMax]);


    return (
        <>
            <h2>
                {
                formData.goal === "FIT" ? "התוכנית היחידה והאחרונה שתצטרכו כדי לרדת במשקל!" :
                formData.goal === "BULK" ? "התוכנית היחידה והאחרונה שתצטרכו כדי להעלות במסת השריר!" :
                "התוכנית היחידה והאחרונה שתצטרכו כדי להתחטב!"
                }
            </h2>
            <p>לפי הנתונים שלך תוך 3 חודשים, בתאריך: {formattedDate} תוכלו להגיע למשקל {Number(formData.weight)+(weightIncrement*3)} ק"ג.</p>
            <div className='toolTipGraph' style={formData.goal === "BULK" ? { float: "right" } : {}}>
                {formData.goal === "BULK" ? `${Number(formData.weight)+(weightIncrement*3)} ק"ג` :
                `${Number(formData.weight)} ק"ג`
                }
            </div>
            <div style={{width:"90%", margin:"auto"}}>
                <canvas ref={chartRef}  style={{ height: '200px', width: '100%' }}></canvas>
            </div>
            <div className='toolTipGraphEnd' style={formData.goal === "BULK" ? { float: "left" } : {}}>
                {formData.goal === "BULK" ? `${Number(formData.weight)} ק"ג` :
                `${Number(formData.weight)+(weightIncrement*3)} ק"ג`
                }
            </div>
            <div style={{height:50}}></div>
        </>
    )
}

export default Info2