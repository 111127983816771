import React, { useState } from 'react';

export function Gender({genderRadio, setGenderRadio, page, setPage}) {

    return (
        <>
        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="gender" value="MALE"
            onChange={(e) => setGenderRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            גבר
            <img className="img-form" src="img/man.png"/>
        </label>

        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="gender" value="FEMALE"
            onChange={(e) => setGenderRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            אישה
            <img className="img-form" src="img/female.png"/>
        </label>
        </>
    )
}

export default Gender
