import React, { useState } from 'react';

export function Place({placeRadio, setPlaceRadio, page, setPage}) {

    return (
        <>
        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="place" value="GYM"
            onChange={(e) => setPlaceRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            חדר כושר
            <img className="img-form" src="img/gym.png"/>
        </label>

        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="place" value="HOME"
            onChange={(e) => setPlaceRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            אימונים בבית
            <img className="img-form" src="img/home.png"/>
        </label>
        </>
    )
}

export default Place
