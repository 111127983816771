import { Questions } from './Questions';
import React, { useState } from 'react';
import { Intro } from './Intro';
import Login from './Login';

export function Auth ({authenticated, setAuthenticated}){

    const [intro, setIntro] = useState(true);
    const [login, setLogin] = useState(false);

    if (login === true){
        return(
            <>
            <Login authenticated={authenticated} setAuthenticated={setAuthenticated}/>
            <div className="form-footer">
                <button onClick={() => {setLogin(false)}} className="button-white-border">חזרה</button>
            </div>
            </>
        )
    }


    if (intro === true){
        return(
            <>
            <Questions/>
            <button onClick={() => {setIntro(false)}}
            style={{position:"fixed",bottom:20,right:40,left:40,width:"50%",margin:"auto",padding:10,border:"none",zIndex:-1,display:"none"}} className="button-white-border">חזרה</button>
            </>
        );
        
     } else{
         return(
            <>
            <Intro/>
            <div style={{position:"absolute",bottom:32,right:32,left:32, maxWidth:500, margin:"auto"}}>
                <button onClick={() => {setIntro(true)}} style={{marginBottom:20}} className="button-blue" type="button">המשך לשאלון</button>
                <p>יש לך כבר משתמש?</p>
                <button onClick={() => {setLogin(true)}} style={{width:140,padding:8,marginTop:-4,fontSize:16}} className="button-white-border" type="button">התחברות</button>
            </div>
            </>
         );
        }
}

export default Auth;