import React, { useState, useEffect } from 'react';

export function Info1({page, setPage, questionAnswered, setQuestionAnswered, formData, setFormData}) {

    useEffect(() => {
        setQuestionAnswered(1);
    }, [page]);

    useEffect(() => {
        const Swiper = window.Swiper;

        new Swiper('.swiper-container', {
            slidesPerView: 2,
            spaceBetween: 16,
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false, // Continue autoplay after user interaction
            },
        });
    }, []);

    if(formData.gender === "FEMALE"){
        return (
            <>
                <h2>
                    {
                    formData.goal === "FIT" ? "המאבק בירידה במשקל יכול להיות מתסכל..." :
                    formData.goal === "BULK" ? "המאבק בעלייה במשקל יכול להיות מתסכל..." :
                    "המאבק בחיטוב הגוף יכול להיות מתסכל..."
                    }
                </h2>

                <p>
                    בטח גם את ניסית דיאטה כזו או אחרת ולא הצלחת, כנראה מאחת הסיבות הבאות:
                </p>

                <div className="bullets-info" style={{margin:0}}>
                    <div className="feature-wrap-info">
                        <img className="tick" src="img/tick-green.svg"/>
                        <p style={{fontSize:14,marginBottom:0}}>חוסר ידע</p>
                    </div>
                    <div className="feature-wrap-info">
                        <img className="tick" src="img/tick-green.svg"/>
                        <p style={{fontSize:14,marginBottom:0}}>חוסר איזון הורמונלי</p>
                    </div>
                    <div className="feature-wrap-info">
                        <img className="tick" src="img/tick-green.svg"/>
                        <p style={{fontSize:14,marginBottom:0}}>
                        {
                        formData.goal === "BULK" ? "חוסר תיאבון" :
                        "חשק למתוקים"
                        }
                        </p>
                    </div>

                </div>

                <p className="little-title" style={{marginBottom:10,opacity:"100%"}}>הנשים האלו התגברו על הקשיים והצליחו להגיע למטרה שלהן, זה אפשרי! 👇</p>

                <div className="swiper-container">
                    <div className="swiper-wrapper" style={{zIndex:0}}>
                        <div className="swiper-slide">
                            <img className="infoImage" src="https://liorgenishonline.co.il/wp-content/uploads/2023/12/WhatsApp-Image-2023-12-09-at-22.22.36.jpeg"/>
                        </div>
                        <div className="swiper-slide">
                            <img className="infoImage" src="https://liorgenishonline.co.il/wp-content/uploads/2022/05/Layer-4.jpg"/>
                        </div>
                        <div className="swiper-slide">
                            <img className="infoImage" src="https://liorgenishonline.co.il/wp-content/uploads/2022/05/Layer-6.jpg"/>
                        </div>
                        <div className="swiper-slide">
                            <img className="infoImage" src="https://liorgenishonline.co.il/wp-content/uploads/2022/05/Layer-7.jpg"/>
                        </div>
                        <div className="swiper-slide">
                            <img className="infoImage" src="https://liorgenishonline.co.il/wp-content/uploads/2023/03/71304544_2490276157705334_8667750852418600960_n.jpg"/>
                        </div>
                        <div className="swiper-slide">
                            <img className="infoImage" src="https://liorgenishonline.co.il/wp-content/uploads/2023/03/5f6788252a8208ccbdba5311_72084635_2490277441038539_3981735296742981632_n-p-800.jpeg"/>
                        </div>
                    </div>
                </div>
                
            </>
        )
    }
    else{
        return (
            <>
                <h2>
                    {
                    formData.goal === "FIT" ? "המאבק בירידה במשקל יכול להיות מתסכל..." :
                    formData.goal === "BULK" ? "המאבק בעלייה במשקל יכול להיות מתסכל..." :
                    "המאבק בחיטוב הגוף יכול להיות מתסכל..."
                    }
                </h2>

                <p>
                    {
                    formData.goal === "FIT" ? "אם גם אתה ניסית לרדת במשקל ולא הצלחת, רוב הסיכויים שזה מאחת הסיבות הבאות:" :
                    formData.goal === "BULK" ? "אם גם אתה ניסית לעלות במסת השריר ולא הצלחת, רוב הסיכויים שזה מאחת הסיבות הבאות:" :
                    "אם גם אתה ניסית להתחטב ולא הצלחת, רוב הסיכויים שזה מאחת הסיבות הבאות:"
                    }
                </p>

                <div className="bullets-info" style={{margin:0}}>
                    <div className="feature-wrap-info">
                        <img className="tick" src="img/tick-green.svg"/>
                        <p style={{fontSize:14,marginBottom:0}}>חוסר<br></br>ידע</p>
                    </div>
                    <div className="feature-wrap-info">
                        <img className="tick" src="img/tick-green.svg"/>
                        <p style={{fontSize:14,marginBottom:0}}>
                            {
                            formData.goal === "BULK" ? <>חוסר<br></br>תיאבון</> :
                            <>חשק<br></br>למתוקים</>
                            }
                        </p>
                    </div>
                    <div className="feature-wrap-info">
                        <img className="tick" src="img/tick-green.svg"/>
                        <p style={{fontSize:14,marginBottom:0}}>מחסור במוטיבציה והרגלים</p>
                    </div>

                </div>

                <p className="little-title" style={{marginBottom:10,opacity:"100%"}}>האנשים האלו הצליחו להתגבר על הקשיים וכבר הגיעו לגוף החלומות שלהם, זה אפשרי! 👇</p>

                <div className="swiper-container">
                    <div className="swiper-wrapper" style={{zIndex:0}}>
                        <div className="swiper-slide">
                            <img className="infoImage" src="https://liorgenishonline.co.il/wp-content/uploads/2023/03/WhatsApp-Image-2020-09-02-at-14.22.06.jpeg"/>
                        </div>
                        <div className="swiper-slide">
                            <img className="infoImage" src="https://liorgenishonline.co.il/wp-content/uploads/2023/04/WhatsApp-Image-2023-04-20-at-14.24.20-1.jpeg"/>
                        </div>
                        <div className="swiper-slide">
                            <img className="infoImage" src="https://liorgenishonline.co.il/wp-content/uploads/2023/03/71222138_2490277861038497_669797173398863872_n.jpg"/>
                        </div>
                        <div className="swiper-slide">
                            <img className="infoImage" src="https://liorgenishonline.co.il/wp-content/uploads/2023/04/WhatsApp-Image-2023-04-20-at-14.24.57-1.jpeg"/>
                        </div>
                        <div className="swiper-slide">
                            <img className="infoImage" src="https://liorgenishonline.co.il/wp-content/uploads/2023/12/WhatsApp-Image-2023-12-09-at-22.19.53.jpeg"/>
                        </div>
                        <div className="swiper-slide">
                            <img className="infoImage" src="https://liorgenishonline.co.il/wp-content/uploads/2023/03/72118249_2490275524372064_12095375229845504_n.jpg"/>
                        </div>
                    </div>
                </div>
                
            </>
        )
    }
    
}

export default Info1
