import React, { useState } from 'react';

export function Nutrition({nutritionRadio, setNutritionRadio, page, setPage}) {

    return (
        <>
        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="nutrition" value="ALL"
            onChange={(e) => setNutritionRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            אוכל הכל
            <img style={{transform:"scale(0.9)"}} className="img-form" src="img/meat.png"/>
        </label>

        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="nutrition" value="VEGETARIAN"
            onChange={(e) => setNutritionRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            צמחוני
            <img style={{transform:"scale(0.9)"}} className="img-form" src="img/milk.png"/>
        </label>

        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="nutrition" value="VEGAN"
            onChange={(e) => setNutritionRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            טבעוני
            <img style={{transform:"scale(0.9)"}} className="img-form" src="img/vegan.png"/>
        </label>

        </>
    )
}

export default Nutrition
