export const Constants = {
    VALIDATION_ERROR: 400,
    UNAUTHURIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    SERVER_ERROR: 500,
    INACTIVE_BONUS: 0,
    MEDIUM_ACTIVE_BONUS: 150,
    VERY_ACTIVE_BONUS: 300,
    BULK_BONUS_MALE: 500,
    BULK_BONUS_FEMALE: 250,
    CUT_BONUS: 0.024,
    CALORIES_WORKOUT: 30,
    HEALTHY_BONUS: 0,
    MAX_NUM_RETRIES: 5,
    CALORIES_IN_KG: 7700
};

export default Constants