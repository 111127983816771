import React, { useState } from 'react';

export function Nav({nav, setNav}) {
    return (
        <>
        <div className="nav-wrapper">
            <div className="nav-button"
            onClick={() => {setNav(0)}}
            >
                <img src="Home.svg"/>
            </div>
            <div className="nav-button"
            onClick={() => {setNav(1)}}
            >
                <img src="Nutrition.svg"/>
            </div>
            <div className="nav-button"
            onClick={() => {setNav(2)}}
            >
                <img src="Workout.svg"/>
            </div>
            <div className="nav-button"
            onClick={() => {setNav(3)}}
            >
                <img src="Profile.svg"/>
            </div>
            <div className="nav-button"
            onClick={() => {setNav(4)}}
            >
                <img src="Stats.svg"/>
            </div>
        </div>
        </>
    )
}

export default Nav;
