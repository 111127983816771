import React, { useState, useEffect } from 'react';

export function Fat({page, setPage, formData, setFormData, setQuestionAnswered, questionAnswered}) {
    
    const [pointerPosition, setPointerPosition] = useState(40); // Default to 37.5

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue >= 0 && inputValue <= 8) {
          setQuestionAnswered(1);
          document.getElementById("movetheslide").style.display = "none";
        }
        else{
            setQuestionAnswered(0);
        }
        // Directly compute and update pointerPosition here
        const newPosition = (100 * (inputValue / 7.5)).toFixed(1);
        setPointerPosition(newPosition);
        document.getElementsByClassName('fatPointer')[0].style.right = `${newPosition}%`;
    };

    /*useEffect(() => {
        console.log(formData.bodyfat);
    }, [formData.bodyfat]);*/
    

    if(formData.gender === "MALE"){

        return (
            <>
            
            <div className="fat-images">
            {
            formData.bodyfat === "0" ? <img src="img/menfat-1.png"></img> :
            formData.bodyfat === "1" ? <img src="img/menfat-2.png"></img> :
            formData.bodyfat === "2" ? <img src="img/menfat-3.png"></img> :
            formData.bodyfat === "4" ? <img src="img/menfat-5.png"></img> :
            formData.bodyfat === "5" ? <img src="img/menfat-6.png"></img> :
            formData.bodyfat === "6" ? <img src="img/menfat-7.png"></img> :
            formData.bodyfat === "7" ? <img src="img/menfat-8.png"></img> :
            <img src="img/menfat-4.png"></img>
            }
            </div>
            <div className="fatRangeWrapper">
                <div className="fatPointer">
                {
                formData.bodyfat === "0" ? "5-9%" :
                formData.bodyfat === "1" ? "10-14%" :
                formData.bodyfat === "2" ? "15-19%" :
                formData.bodyfat === "4" ? "25-29%" :
                formData.bodyfat === "5" ? "30-34%" :
                formData.bodyfat === "6" ? "35-39%" :
                formData.bodyfat === "7" ? ">40%" :
                "20-24%"
                }
                <div className="fatPointerArrow"></div></div>
                <input className="fat-range"
                type="range"
                min="0"
                max="7"
                value={formData.bodyfat || "3"}
                onChange={(e) => {setFormData({...formData, bodyfat: e.target.value}); handleInputChange(e)}}/>
            </div>
            <div id="movetheslide" style={{paddingLeft:"13%"}}>
                <p>הזיזו את הסמן</p>
            </div>

        </>
        )
    }
    else{
        return (

        <>  
            <div className="fat-images">
            {
            formData.bodyfat === "0" ? <img src="img/femalefat-1.png"></img> :
            formData.bodyfat === "1" ? <img src="img/femalefat-2.png"></img> :
            formData.bodyfat === "2" ? <img src="img/femalefat-3.png"></img> :
            formData.bodyfat === "4" ? <img src="img/femalefat-5.png"></img> :
            formData.bodyfat === "5" ? <img src="img/femalefat-6.png"></img> :
            formData.bodyfat === "6" ? <img src="img/femalefat-7.png"></img> :
            formData.bodyfat === "7" ? <img src="img/femalefat-8.png"></img> :
            <img src="img/femalefat-4.png"></img>
            }
            </div>
            <div className="fatRangeWrapper">
                <div className="fatPointer">
                {
                formData.bodyfat === "0" ? "15-19%" :
                formData.bodyfat === "1" ? "20-24%" :
                formData.bodyfat === "2" ? "25-29%" :
                formData.bodyfat === "4" ? "35-39%" :
                formData.bodyfat === "5" ? "40-44%" :
                formData.bodyfat === "6" ? "45-49%" :
                formData.bodyfat === "7" ? ">50%" :
                "30-34%"
                }
                <div className="fatPointerArrow"></div></div>
                <input className="fat-range"
                type="range"
                min="0"
                max="7"
                value={formData.bodyfat || "3"}
                onChange={(e) => {setFormData({...formData, bodyfat: e.target.value}); handleInputChange(e)}}/>
            </div>
            <div id="movetheslide" style={{paddingLeft:"13%"}}>
                <p>הזיזו את הסמן</p>
            </div>

        </>
        )
    };
    
}

export default Fat
