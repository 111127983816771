import React, { useState } from 'react';

export function Profile() {
    return (
        <>
        <h2>המשתמש שלי</h2>
        </>
    )
}

export default Profile;
