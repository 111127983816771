import React from 'react'

export function Intro() {
    return (
        <>
        <h2>ברוכים הבאים לפיט באדי!</h2>
        <p style={{zIndex:1}}>אנחנו צריכים להכיר אותך קצת יותר על מנת שנוכל לבנות תוכנית אימונים ותפריט תזונה מותאמים אישית עבורך.</p>
        <img style={{width: "130%",maxWidth:"100vw", margin:-32,marginTop:-60,marginBottom:-100,zIndex:-1,position:"relative"}} src="register-bg.jpg"/>
        </>
    )
}

export default Intro;
