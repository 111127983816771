import React, { useState } from 'react';
import './App.css';
import { Auth } from './Auth';
import NutritionMenu from './NutritionMenu';
import { Home } from './Home';
import Nav from './Nav';
import Profile from './Profile';
import Stats from './Stats';
import Workout from './Workout';
import { app } from './firebaseConfig.js';

function App() {

    // Initialize Firebase
    /*const firebaseConfig = {
        apiKey: "AIzaSyC-pOrVQ22BmmD8TGQpgMHyr_PrHTo-jYw",
        authDomain: "fitbuddy-85b27.firebaseapp.com",
        projectId: "fitbuddy-85b27",
        storageBucket: "fitbuddy-85b27.appspot.com",
        messagingSenderId: "312024751054",
        appId: "1:312024751054:web:f53480cbc0b4d3266ae99f",
        measurementId: "G-3PSCXCBTJC"
    };
    const FireBaseApp = initializeApp(firebaseConfig);
    // Initialize Firebase Authentication and get a reference to the service
    const auth = getAuth(FireBaseApp);

    console.log(FireBaseApp); */
    
    const [authenticated, setAuthenticated] = useState(false);
    const [nav, setNav] = useState(0);

    //let authenticated = false;
    
    if (authenticated){
        //כאן צריך פקודת גט עם כל הדטא של המשתמש
        if (nav === 0){
            return(
                <>
                <div className="main-header">
                    <img src="FitBuddyLogo.svg" id="logo"/>
                </div>
                <Home/>
                <Nav nav={nav} setNav={setNav}/>
                </>
            );
        }
        if (nav === 1){
            return(
                <>
                <div className="main-header">
                    <img src="FitBuddyLogo.svg" id="logo"/>
                </div>
                <NutritionMenu/>
                <Nav nav={nav} setNav={setNav}/>
                </>
            );
        }
        if (nav === 2){
            return(
                <>
                <div className="main-header">
                    <img src="FitBuddyLogo.svg" id="logo"/>
                </div>
                <Workout/>
                <Nav nav={nav} setNav={setNav}/>
                </>
            );
        }
        if (nav === 3){
            return(
                <>
                <div className="main-header">
                    <img src="FitBuddyLogo.svg" id="logo"/>
                </div>
                <Profile/>
                <Nav nav={nav} setNav={setNav}/>
                </>
            );
        }
        if (nav === 4){
            return(
                <>
                <div className="main-header">
                    <img src="FitBuddyLogo.svg" id="logo"/>
                </div>
                <Stats/>
                <Nav nav={nav} setNav={setNav}/>
                </>
            );
        }
        
     } else{
         return(
            <>
            <div className="main-header">
                    <img src="FitBuddyLogo.svg" id="logo"/>
            </div>
            <Auth authenticated={authenticated} setAuthenticated={setAuthenticated}/>
            </>
         );
        }
    
  }

export default App;
