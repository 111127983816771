import React, { useState, useEffect } from 'react';
import { getAuth, getUserByEmail } from "firebase/auth";

export function Register({formData, setFormData, questionAnswered, setQuestionAnswered, page, setPage, availableEmail}) {

    const [inputsFilled, setInputsFilled] = useState(false);

    const handleInputChange = () => {
        const inputs = document.querySelectorAll('.form-register');
        let allFilled = true;

        inputs.forEach(input => {
        if (input.value === '') {
            allFilled = false;
        }
        });

        setInputsFilled(allFilled);
    };

    useEffect(() => {
        if (inputsFilled) {
        setQuestionAnswered(1);
        } else {
        setQuestionAnswered(0);
        }
    }, [inputsFilled, setQuestionAnswered]);


    const handleKeyPress = (e) =>
    {
        if (e.key === 'Enter' && questionAnswered === 1)
        {
            availableEmail();
        }
    };

    return (
        <>

        <label className="register-label">כתובת מייל</label>
        <input className="form-text form-register" type="email" value={formData.email}
        onChange={(e) => {setFormData({...formData, email: e.target.value}); handleInputChange(e)}}
        onKeyDown={handleKeyPress}
        />
        <span style={{textAlign:"right",fontSize:13,margin:10,marginTop:4,display:"flex",justifyContent:"center",alignItems:"center",gap:8,opacity:0.7}}>
            <img style={{width:20,height:20,display:"inline-block"}}src="img/secure.svg"></img>
            אנו מכבדים את פרטיותך ומחויבים להגן על הנתונים האישיים שלך.
        </span>

        </>
    )
}

export default Register
