import React from 'react'

export function Goal({goalRadio, setGoalRadio, page, setPage, formData, setFormData}) {

    if(formData.gender === "MALE"){

        return (
            <>
            <label className="radio-wrap">
                <input className="form-radio" type="radio" name="goal" value="FIT"
                onChange={(e) => setGoalRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                ירידה במשקל
                <img style={{transform:"scale(0.9)"}} className="img-form" src="img/get-fit.png"/>
            </label>

            <label className="radio-wrap">
                <input className="form-radio" type="radio" name="goal" value="BULK"
                onChange={(e) => setGoalRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                העלאה במסת השריר
                <img style={{transform:"scale(0.9)"}} className="img-form" src="img/get-muscle.png"/>
            </label>

            <label className="radio-wrap">
                <input className="form-radio" type="radio" name="goal" value="HEALTH"
                onChange={(e) => setGoalRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                חיטוב הגוף ואורח חיים בריא
                <img style={{transform:"scale(0.9)"}} className="img-form" src="img/get-tone.png"/>
            </label>
            </>
        )
    
    }
    else{

        return (
            <>
            <label className="radio-wrap">
                <input className="form-radio" type="radio" name="goal" value="FIT"
                onChange={(e) => setGoalRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                ירידה במשקל
                <img style={{transform:"scale(0.9)"}} className="img-form" src="img/get-fit.png"/>
            </label>
    
            <label className="radio-wrap">
                <input className="form-radio" type="radio" name="goal" value="BULK"
                onChange={(e) => setGoalRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                העלאה במסת השריר
                <img style={{transform:"scale(0.9)"}} className="img-form" src="img/get-muscle-woman.png"/>
            </label>
    
            <label className="radio-wrap">
                <input className="form-radio" type="radio" name="goal" value="HEALTH"
                onChange={(e) => setGoalRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                חיטוב הגוף ואורח חיים בריא
                <img style={{transform:"scale(0.9)"}} className="img-form" src="img/get-tone-woman.png"/>
            </label>
            </>
        )
    };
    
}

export default Goal
