import React, { useState } from 'react';

export function Home() {
    return (
        <>
        <h2>ברוכים הבאים לפיט באדי!</h2>
        </>
    )
}

export default Home;
