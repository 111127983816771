import React, { useState } from 'react';

export function Workout() {
    return (
        <>
        <h2>תוכנית אימונים</h2>
        </>
    )
}

export default Workout;
