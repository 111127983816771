import React from 'react'
import { useEffect } from 'react'

export function Target({targetRadio, setTargetRadio, page, setPage, formData, setFormData}) {

    
    if(formData.gender === "MALE"){

        return (
            <>
            <label className="muscle-wrap">
                <img className="img-muscle" src="img/male-shoulders.png"/>
                <input className="form-radio" type="radio" name="target" value="SHOULDERS"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                כתפיים
            </label>

            <label className="muscle-wrap">
                <img style={{marginLeft:7}} className="img-muscle" src="img/male-biceps.png"/>
                <input className="form-radio" type="radio" name="target" value="ARMS"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                ידיים
            </label>

            <label className="muscle-wrap">
                <img className="img-muscle" src="img/male-chest.png"/>
                <input className="form-radio" type="radio" name="target" value="CHEST"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                חזה
            </label>

            <label className="muscle-wrap">
                <img className="img-muscle" src="img/male-back.png"/>
                <input className="form-radio" type="radio" name="target" value="BACK"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                גב
            </label>

            <label className="muscle-wrap">
                <img style={{marginLeft:7}} className="img-muscle" src="img/male-belly.png"/>
                <input className="form-radio" type="radio" name="target" value="BELLY"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                בטן
            </label>

            <label className="muscle-wrap">
                <img className="img-muscle" src="img/male-legs.png"/>
                <input className="form-radio" type="radio" name="target" value="LEGS"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                רגליים
            </label>

            <label className="radio-wrap" style={{width:"81%",marginRight:"5%",marginBottom:100}}>
                <img style={{marginLeft:24}} className="img-form" src="img/male-all.png"/>
                <input className="form-radio" type="radio" name="target" value="ALL"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                כל הגוף
            </label>

            </>
        )
    
    }
    else{

        return (
            <>
            <label className="muscle-wrap">
                <img className="img-muscle" src="img/female-shoulders.png"/>
                <input className="form-radio" type="radio" name="target" value="SHOULDERS"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                כתפיים
            </label>

            <label className="muscle-wrap">
                <img style={{marginLeft:7}} className="img-muscle" src="img/female-biceps.png"/>
                <input className="form-radio" type="radio" name="target" value="ARMS"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                ידיים
            </label>

            <label className="muscle-wrap">
                <img className="img-muscle" src="img/female-chest.png"/>
                <input className="form-radio" type="radio" name="target" value="CHEST"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                חזה
            </label>

            <label className="muscle-wrap">
                <img className="img-muscle" src="img/female-back.png"/>
                <input className="form-radio" type="radio" name="target" value="BACK"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                גב
            </label>

            <label className="muscle-wrap">
                <img style={{marginLeft:7}} className="img-muscle" src="img/female-belly.png"/>
                <input className="form-radio" type="radio" name="target" value="BELLY"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                בטן
            </label>

            <label className="muscle-wrap">
                <img className="img-muscle" src="img/female-legs.png"/>
                <input className="form-radio" type="radio" name="target" value="LEGS"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                רגליים וישבן
            </label>

            <label className="radio-wrap" style={{width:"81%",marginRight:"5%",marginBottom:100}}>
                <img style={{marginLeft:24}} className="img-form" src="img/female-all.png"/>
                <input className="form-radio" type="radio" name="target" value="ALL"
                onChange={(e) => setTargetRadio(e.target.value)}
                onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
                />
                כל הגוף
            </label>
            
            </>
        )
    };
    
}

export default Target
