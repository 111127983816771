import React from 'react'

export function Height({formData, setFormData, questionAnswered, setQuestionAnswered, page, setPage}) {

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue>15) {
          setQuestionAnswered(1);
        }
        else{
            setQuestionAnswered(0);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && questionAnswered === 1) {
            setTimeout(() => {
                setPage(page + 1);
                setQuestionAnswered(0);
            }, 250);
        }
    };


    return (
        <>
        <input className="form-text"
        type="number"
        min="20"
        max="400"
        placeholder="170 ס&quot;מ"
        value={formData.height}
        onChange={(e) => {setFormData({...formData, height: e.target.value}); handleInputChange(e)}}
        onKeyDown={handleKeyPress}
        />
        </>
    )
}

export default Height
