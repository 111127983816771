import React from 'react'

export function Active({activeRadio, setActiveRadio, page, setPage}) {

    return (
        <>
        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="dailyActivity" value="INACTIVE"
            onChange={(e) => setActiveRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            אורח חיים יושבני ופעיל מעט
        </label>

        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="dailyActivity" value="MEDIUM_ACTIVE"
            onChange={(e) => setActiveRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            פעיל במידה מתונה
        </label>

        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="dailyActivity" value="VERY_ACTIVE"
            onChange={(e) => setActiveRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            אורח חיים מאוד פעיל
        </label>
        </>
    )
    
}

export default Active
