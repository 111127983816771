import React, { useState, useEffect } from 'react';

export function Generating({page, setPage}) {

    const [progress, setProgress] = useState(0);
    const [text, setText] = useState('יוצר עבורך תפריט תזונה ותוכנית אימונים מותאמים אישית...');

    useEffect(() => {
        // If progress is 100, wait for 0.5 seconds and change the text
        if (progress == 1) {
            //alert(page);
            //setPage(page + 1);
        }
        if (progress === 100) {
        setText('זהו, הכל מוכן!');
        setTimeout(() => setPage(page + 1), 900);
    }

    // Increment progress
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 1
      );
    }, 100);

    // Clear interval on cleanup
    return () => clearInterval(interval);
    }, [progress]);

    return (
        <div className='loader-container'>
        <div className='loader' style={{ width: '200px', height: '200px', position: 'relative' }}>
            <svg viewBox='0 0 36 36' className='circular-chart'>
                    <defs>
                    <linearGradient id='gradient' x1='0%' y1='0%' x2='100%' y2='0%'>
                    <stop offset='0%' style={{stopColor: '#C5FF10', stopOpacity: 1}} />
                    <stop offset='100%' style={{stopColor: '#63E51F', stopOpacity: 1}} />
                    </linearGradient>
                </defs>
                <path
                    className='circle'
                    stroke='url(#gradient)'
                    strokeDasharray={`${progress}, 100`}
                    d='M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831'
                    fill='none'
                    strokeWidth='3.8'
                ></path>
            </svg>
            <div
            className='percentage'
            style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '1.5em' }}
            >
            {progress}%
            </div>
        </div>
        <p style={{ width: '250px', margin:'auto', marginTop:32}}>{text}</p>
        </div>
    )
    
}

export default Generating
