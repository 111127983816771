import React, { useState} from 'react';

export function Level({formData, setFormData, questionAnswered, setQuestionAnswered}) {

  const levelTitle = [
    "מתחיל",
    "מתחיל",
    "מתחיל",
    "בינוני",
    "בינוני",
    "בינוני",
    "בינוני",
    "מתקדם",
    "מתקדם",
    "מתקדם",
    "מתקדם",
  ]

  const levelDesc = [
    "כשאני יושב על הרצפה, קשה לי לקום.",
    "אחרי שעליתי או ירדתי במדרגות, אני צריך להסדיר את הנשימה.",
    "אני יכול לעשות הליכה בתור אירובי, אבל ריצה זה קשה.",
    "מתאמן כמה פעמים בשנה.",
    "מנסה להתאמן פעם בשבוע אך לא באופן סדיר.",
    "התחלתי להתאמן לפחות פעם בשבוע על בסיס קבוע.",
    "אני מתאמן פעמיים בשבוע לפחות ב-3 החודשים האחרונים.",
    "אני מחויב לכושר ומתאמן מתי שאני יכול.",
    "אני בכושר מצוין, אבל אני עדיין רוצה לעלות רמה אחת מעל.",
    "אני בכושר הכי טוב של חיי!",
  ]

  const handleInputChange = (e) => {
    document.getElementById("movetheslide").style.display = "none";
    const inputValue = e.target.value;
    if (inputValue >= 0 && inputValue <= 10) {
      setQuestionAnswered(1);
    }
    else{
        setQuestionAnswered(0);
    }
  };

    return (
        <>
        <h2>{formData.fitnessLevel}</h2>
        <input className="form-range"
        type="range"
        min="1"
        max="10"
        value={formData.fitnessLevel || "1"}
        onChange={(e) => {setFormData({...formData, fitnessLevel: e.target.value}); handleInputChange(e)}}/>
        <h2>{levelTitle[formData.fitnessLevel-1]}</h2>
        <p>{levelDesc[formData.fitnessLevel-1]}</p>
        <div id="movetheslide">
                <p>הזיזו את הסמן</p>
        </div>
        </>
    )
}

export default Level
