import React, { useState } from 'react';

export function Login({authenticated, setAuthenticated}) {

    const [loginData, setLoginData] = useState({
        email: null,
        password: null
    });

    function handleEmailChange(event) {
        setLoginData({ ...loginData, email: event.target.value });
    }

    function handlePasswordChange(event) {
        setLoginData({ ...loginData, password: event.target.value });
    }

    function logged() {

        fetch('http://16.171.0.3:5001/api/users/login', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(loginData)
        })
        
        .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            console.log('Login successful:', data);
            setAuthenticated(true);
          })
          .catch(error => {
            // Handle errors
            console.error('There has been a problem with your fetch operation:', error);
          });

        console.log(loginData);
    }

    return (
        <>
        <h1>התחברות</h1>
        <label className="register-label">כתובת מייל</label>
        <input className="form-text" type="email"
         onChange={handleEmailChange}
        />

        <label className="register-label">סיסמה</label>
        <input className="form-text" type="password"
        onChange={handlePasswordChange}
        />
        <button style={{marginTop:16}} className="button-blue"
        onClick={logged}
        >התחברות</button>
        </>
    )
}

export default Login;
