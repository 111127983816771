import React, { useState } from 'react';

export function Stats() {
    return (
        <>
        <h2>הסטטיסטיקות שלי</h2>
        </>
    )
}

export default Stats;
