import React, { useState } from 'react';

export function NutritionMenu() {
    return (
        <>
        <h2>תפריט תזונה</h2>
        </>
    )
}

export default NutritionMenu;
