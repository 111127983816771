import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyC-pOrVQ22BmmD8TGQpgMHyr_PrHTo-jYw",
  authDomain: "fitbuddy-85b27.firebaseapp.com",
  projectId: "fitbuddy-85b27",
  storageBucket: "fitbuddy-85b27.appspot.com",
  messagingSenderId: "312024751054",
  appId: "1:312024751054:web:f53480cbc0b4d3266ae99f",
  measurementId: "G-3PSCXCBTJC"
};
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);