import React from 'react'

export function Age({formData, setFormData, questionAnswered, setQuestionAnswered, setPage, page}) {

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue > 15 && inputValue < 99) {
          setQuestionAnswered(1);
        }
        else{
            setQuestionAnswered(0);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && questionAnswered === 1) {
            setTimeout(() => {
                setPage(page + 1);
                setQuestionAnswered(0);
            }, 250);
        }
    };

    return (
        <>
        <input className="form-text"
        type="number"
        min="16"
        max="99"
        placeholder="25"
        value={formData.age}
        onChange={(e) => {setFormData({...formData, age: e.target.value}); handleInputChange(e)}}
        onKeyDown={handleKeyPress}
        />
        </>
    )
}

export default Age
