import React, { useState, useEffect } from 'react';

export function BMI({page, setPage, questionAnswered, setQuestionAnswered, formData, setFormData}) {

    const BMI = (formData.weight / ((formData.height / 100) ** 2)).toFixed(1);

    let rightPercentage = ((BMI - 10) * (90 - 0) / (35 - 10)).toFixed(0);

    useEffect(() => {
        setQuestionAnswered(1);
        rightPercentage = Math.max(0, Math.min(rightPercentage, 90));

        setTimeout(() => {
            document.getElementById('bmi-pointer').style.right = `${rightPercentage}%`;
        }, 1000);
        
    }, [page]);

    
    return (
        <>
            <div className="bmi-wrapper">
                <div className="bmi-half" style={{ zIndex: 1 }}>
                    <div className="bmi-profile">
                        <p className="little-title">מטרה</p>
                        <p style={{marginTop:-6}}>
                            {
                                formData.goal === "FIT" ? "ירידה במשקל" :
                                formData.goal === "BULK" ? "העלאה במסת השריר" :
                                "חיטוב הגוף ואורח חיים בריא"
                            }
                        </p>
                    </div>
                    <div className="bmi-profile">
                        <p className="little-title">רמת כושר</p>
                        <p style={{marginTop:-6}}>
                            {formData.fitnessLevel < 4 ? "מתחיל/ה" :
                            formData.fitnessLevel > 7 ? "מתקדם/ת" :
                            "בינוני/ת"
                            }
                        </p>
                    </div>
                    <div className="bmi-profile">
                        <p className="little-title">רמת פעילות</p>
                        <p style={{marginTop:-6}}>
                            {formData.dailyActivity === "INACTIVE" ? "אורח חיים יושבני ופעיל מעט" :
                            formData.dailyActivity === "MEDIUM_ACTIVE" ? "פעיל במידה מתונה" :
                            "אורח חיים מאוד פעיל"
                            }
                        </p>
                    </div>
                </div>
                <div className="bmi-half" style={{textAlign: "left" }}>
                    {
                    formData.gender === 'MALE' && formData.bodyfat === "0" ? <img className="bmi-man" src="img/menfat-1.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "1" ? <img className="bmi-man" src="img/menfat-2.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "2" ? <img className="bmi-man" src="img/menfat-3.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "3" ? <img className="bmi-man" src="img/menfat-4.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "4" ? <img className="bmi-man" src="img/menfat-5.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "5" ? <img className="bmi-man" src="img/menfat-6.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "6" ? <img className="bmi-man" src="img/menfat-7.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "7" ? <img className="bmi-man" src="img/menfat-8.png"/> :

                    formData.gender === 'FEMALE' && formData.bodyfat === "0" ? <img className="bmi-man" src="img/femalefat-1.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "1" ? <img className="bmi-man" src="img/femalefat-2.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "2" ? <img className="bmi-man" src="img/femalefat-3.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "3" ? <img className="bmi-man" src="img/femalefat-4.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "4" ? <img className="bmi-man" src="img/femalefat-5.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "5" ? <img className="bmi-man" src="img/femalefat-6.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "6" ? <img className="bmi-man" src="img/femalefat-7.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "7" ? <img className="bmi-man" src="img/femalefat-8.png"/> :

                    <img className="bmi-man" src="img/menfat-5.png"/>
                    }
                </div>
            </div>

            <div className="bmi-box-wrapper">
                <div className="bmi-title">
                    <h3 style={{margin:10, marginRight:0}}>ה-BMI שלך</h3>
                    <p className="little-title">BMI תקין בין 18.5 ל-25</p>
                </div>

                <div className="bmi-box">
                    <div className="bmi-scale">
                        <div id="bmi-pointer">{BMI}</div>
                    </div>
                    <h3 style={{fontSize:18,marginTop:24,marginBottom:-20}}>
                        {BMI < 18.5 ? "את/ה בתת משקל" :
                        (BMI >= 18.5 && BMI <= 25) ? "את/ה במשקל תקין" :
                        (BMI > 25 && BMI <= 30) ? "את/ה בעודף משקל" :
                        "את/ה נמצא/ת בהשמנת יתר"
                        }
                    </h3>
                </div>

                <p className="bmi-explain">
                מדד מסת הגוף (BMI) הוא מדד שמשתמש בגובה ובמשקל שלך כדי לבדוק האם המשקל שלך בריא.
                BMI גבוה מעלה, בין היתר, את הסיכון למחלות כלי דם (כלומר מחלות לב, אירוע מוחי ומחלות כליה) ואת הסיכון לסוכרת.
                </p>
            </div>
        </>
    )
    
}

export default BMI
