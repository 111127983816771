import React, { useState, useEffect } from 'react';
import { Constants } from './constants.js';

export function Buynow({page, setPage, formData, setFormData, setQuestionAnswered, questionAnswered}) {

    //CALORIES//

    let goal = formData.goal;
    let height = formData.height;
    let age = formData.age;
    let weight = formData.weight;
    let dailyActivity = formData.dailyActivity;
    let gender = formData.gender;
    let workoutsNumber = formData.workoutsNumber;

    const [recomendedCalories, setRecomendedCalories] = useState(0);

    function getUserMenu()
    {
        const userRmr = calcUserBmr(height, age, weight, gender) * 1.2;
        let userCalories = userRmr;

        switch (dailyActivity)
        {
            case 'INACTIVE':
                userCalories = userCalories + Constants.INACTIVE_BONUS;
                break;
            case 'MEDIUM_ACTIVE':
                userCalories = userCalories + Constants.MEDIUM_ACTIVE_BONUS;
                break;
            case 'VERY_ACTIVE':
                userCalories = userCalories + Constants.VERY_ACTIVE_BONUS;
                break;
            default:
                break;
        }

        userCalories += Constants.CALORIES_WORKOUT * parseFloat(workoutsNumber);

        switch (goal)
        {
            case "BULK":
                userCalories = userCalories + (gender == 'MALE' ? Constants.BULK_BONUS_MALE : Constants.BULK_BONUS_FEMALE);
                break;
            case "FIT":
                let weightToLose = Constants.CUT_BONUS * weight;
                let caloricDeficit = (weightToLose * Constants.CALORIES_IN_KG) / 30;
                userCalories -= caloricDeficit;
                break;
            case "HEALTH":
                userCalories = userCalories + Constants.HEALTHY_BONUS;
                break;
            default:
                break;
        }
        userCalories = roundCalories(userCalories);
        return userCalories;
    }

    function calcUserBmr(height, age, weight, gender)
    {
        let bmr = 0;
        if(gender == 'MALE')
        {
            bmr = 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
        }
        else
        {
            bmr = 447.593 + 9.247 * weight + 3.098 * height - 4.330 * age;
        }
        return bmr;
    }

    function roundCalories(calories)
    {
        if (calories % 100 < 50)
        {
            return calories - (calories % 100);
        }
        else
        {
            return calories + (100 - (calories % 100));
        }
    }

    //CALORIES//



    const BMI = (formData.weight / ((formData.height / 100) ** 2)).toFixed(1);
    let rightPercentage = ((BMI - 10) * (90 - 0) / (35 - 10)).toFixed(0);

    useEffect(() => {
        setQuestionAnswered(1);
        rightPercentage = Math.max(0, Math.min(rightPercentage, 90));
        setRecomendedCalories(getUserMenu());

        setTimeout(() => {
            document.getElementById('bmi-pointer').style.right = `${rightPercentage}%`;
        }, 1000);
        
    }, [page]);

    return (
        <>

            <div className="transformation-box-wrapper">
                <div className="transformation-half">
                    {
                    formData.gender === 'MALE' && formData.bodyfat === "0" ? <img className="before-after-image" src="img/before-1.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "1" ? <img className="before-after-image" src="img/before-2.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "2" ? <img className="before-after-image" src="img/before-3.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "3" ? <img className="before-after-image" src="img/before-4.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "4" ? <img className="before-after-image" src="img/before-5.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "5" ? <img className="before-after-image" src="img/before-6.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "6" ? <img className="before-after-image" src="img/before-7.png"/> :
                    formData.gender === 'MALE' && formData.bodyfat === "7" ? <img className="before-after-image" src="img/before-8.png"/> :

                    formData.gender === 'FEMALE' && formData.bodyfat === "0" ? <img className="before-after-image" src="img/beforef-1.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "1" ? <img className="before-after-image" src="img/beforef-2.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "2" ? <img className="before-after-image" src="img/beforef-3.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "3" ? <img className="before-after-image" src="img/beforef-4.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "4" ? <img className="before-after-image" src="img/beforef-5.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "5" ? <img className="before-after-image" src="img/beforef-6.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "6" ? <img className="before-after-image" src="img/beforef-7.png"/> :
                    formData.gender === 'FEMALE' && formData.bodyfat === "7" ? <img className="before-after-image" src="img/beforef-8.png"/> :

                    <img className="before-after-image" src="img/before-5.png"/>
                    }
                </div>
                <div className="transformation-half">
                {
                formData.gender === 'FEMALE' ? <img className="before-after-image" src="img/woman-after.png"/> :
                <img className="before-after-image" src="img/men-after.png"/>
                }
                </div>
            </div>

            <div className="bmi-box-wrapper" style={{marginBottom:20}}>
                <div className="bmi-title">
                    <h3 style={{margin:10, marginRight:0}}>ה-BMI שלך</h3>
                    <p className="little-title">BMI נורמלי - 21</p>
                </div>

                <div className="bmi-box">
                    <div className="bmi-scale">
                        <div id="bmi-pointer">{BMI}</div>
                    </div>
                    <h3 style={{fontSize:18,marginTop:24,marginBottom:-20}}>
                        {BMI < 18.5 ? "את/ה בתת משקל" :
                        (BMI >= 18.5 && BMI <= 25) ? "את/ה במשקל תקין" :
                        (BMI > 25 && BMI <= 30) ? "את/ה בעודף משקל" :
                        "את/ה נמצא/ת בהשמנת יתר"
                        }
                    </h3>
                </div>

                <p className="bmi-explain">
                מדד מסת הגוף (BMI) הוא מדד שמשתמש בגובה ובמשקל שלך כדי לבדוק האם המשקל שלך בריא.
                BMI גבוה מעלה, בין היתר, את הסיכון למחלות כלי דם (כלומר מחלות לב, אירוע מוחי ומחלות כליה) ואת הסיכון לסוכרת.
                </p>
            </div>

            <div className="stats">

                <div className="stats-half">
                    <p className="stats-number">
                    {
                        formData.workoutsNumber === "2" ? "2" :
                        formData.workoutsNumber === "3" ? "3" :
                        formData.workoutsNumber === "4" ? "4" :
                        formData.workoutsNumber === "5" ? "5" :
                        "1"
                    }
                    </p>
                    <p className="stats-text">מספר אימונים בשבוע</p>
                </div>

                <div className="stats-half">
                    <p className="stats-number">{recomendedCalories}</p>
                    <p className="stats-text">קלוריות ביום</p>
                </div>

            </div>

            <div className="stats">

                <div className="stats-half">
                    <p className="stats-number">
                    {
                        formData.workoutType === "HOME" ? "🏠" :
                        "🏋️‍♂️"
                    }
                    </p>
                    <p className="stats-text">
                    {
                        formData.workoutType === "HOME" ? "אימונים בבית" :
                        "אימונים בחדר כושר"
                    }
                    </p>
                </div>

                <div className="stats-half">
                    <p className="stats-number">
                    {
                        formData.goal === "FIT" ? "📉" :
                        formData.goal === "BULK" ? "💪" :
                        "🍎"
                    }
                    </p>
                    <p className="stats-text">
                    {
                        formData.goal === "FIT" ? "ירידה במשקל" :
                        formData.goal === "BULK" ? "העלאה במסת השריר" :
                        "חיטוב הגוף ואורח חיים בריא"
                    }
                    </p>
                </div>

            </div>

            <div className="what-you-get">
                <h2 style={{marginBottom:16}}>מה תקבלו בפיט באדי?</h2>
                <div className="feature-wrap">
                    <img className="tick" src="img/tick-green.svg"/>
                    <p>תפריט תזונה מותאם אישית אליכם</p>
                </div>
                <div className="feature-wrap">
                    <img className="tick" src="img/tick-green.svg"/>
                    <p>תוכנית אימונים מותאמת אליך ומשתנה בהתאם להתקדמות שלך</p>
                </div>
                <div className="feature-wrap">
                    <img className="tick" src="img/tick-green.svg"/>
                    <p>אפליקציה פשוטה ונוחה לשימוש עם מעקב אחר ההתקדמות שלך</p>
                </div>
                <div className="feature-wrap">
                    <img className="tick" src="img/tick-green.svg"/>
                    <p>ספריית סרטוני הדרכה של מעל 400 תרגילים בחדר כושר או בבית</p>
                </div>
                <div className="feature-wrap">
                    <img className="tick" src="img/tick-green.svg"/>
                    <p>עוזר בינה מלאכותית שיענה לכם על כל שאלה</p>
                </div>
                <div className="feature-wrap">
                    <img className="tick" src="img/tick-green.svg"/>
                    <p>תוצאות מוכחות <strong>לאחר שבועיים!</strong></p>
                </div>
            </div>

            <div className="how-it-works">
                <h2>איך זה עובד?</h2>
                <div className="video-container">
                    <iframe src="https://www.youtube.com/embed/sM7tdlFGpUA" allowFullScreen></iframe>
                </div>
            </div>

            <div className="cta">
                <h2 className="cta-title">14 ימים ראשונים בחינם</h2>
                <p className="cta-subtitle">מבטלים מתי שרוצים, בלחיצת כפתור</p>
                <button className="button-cta" onClick={() => {setPage(page + 1)}}>
                אני רוצה לקבל את התוכנית ואת תפריט התזונה שלי בחינם 
                </button>
            </div>
        </>
    )
    
}

export default Buynow
