import React from 'react'

export function Frequency({frequencyRadio, setFrequencyRadio, page, setPage}) {

    return (
        <>

        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="frequency" value="1"
            onChange={(e) => setFrequencyRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            פעם אחת
        </label>

        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="frequency" value="2"
            onChange={(e) => setFrequencyRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            פעמיים
        </label>

        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="frequency" value="3"
            onChange={(e) => setFrequencyRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            שלוש
        </label>

        <label className="radio-wrap">
            <input className="form-radio" type="radio" name="frequency" value="4"
            onChange={(e) => setFrequencyRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            ארבע
        </label>

        <label className="radio-wrap" style={{marginBottom:100}}>
            <input className="form-radio" type="radio" name="frequency" value="5"
            onChange={(e) => setFrequencyRadio(e.target.value)}
            onClick={() => setTimeout(() => { setPage(page +1) }, 500)}
            />
            חמש
        </label>

        </>
    )
    
}

export default Frequency
